import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import HistoryTab from './HistoryTab'
import BooksTab from './BooksTab'
import AuthorsTab from './AuthorsTab'
import LibbyTimelineTab from './LibbyTimelineTab'
import UsersModal from './UsersModal'
import RegistrationsModal from './RegistrationsModal'
import CreateRegistrationModal from './CreateRegistrationModal'
import CompleteRegistrationModal from './CompleteRegistrationModal'
import Login from './Login'

function App() {

  const [alertText, setAlertText] = useState('')
  const [authorsTabOpen, setAuthorsTabOpen] = useState(false)
  const [booksTabOpen, setBooksTabOpen] = useState(false)
  const [libbyTabOpen, setLibbyTabOpen] = useState(false)
  const [historyTabOpen, setHistoryTabOpen] = useState(true)
  const [sessionInfo, setSessionInfo] = useState(null)
  const [showAlert, setShowAlert] = useState({ show: false, variant: 'primary' })
  const [showCompleteRegistrationModal, setShowCompleteRegistrationModal] = useState(false)
  const [showCreateRegistrationModal, setShowCreateRegistrationModal] = useState(false)
  const [showRegistrationsModal, setShowRegistrationsModal] = useState(false)
  const [showUsersModal, setShowUsersModal] = useState(false)

  const handleTabSelection = (eventKey, evt) => {
    setHistoryTabOpen(eventKey === 'history')
    setBooksTabOpen(eventKey === 'books')
    setAuthorsTabOpen(eventKey === 'authors')
    setLibbyTabOpen(eventKey === 'libby' )
  }

  const handleLoginModalClose = (loginResponse) => {
    if (loginResponse) {
      if (loginResponse.valid) {
        setSessionInfo(loginResponse.valid ? loginResponse.session_info : null)
        console.log('logged in')
        displayAlert(loginResponse.message, loginResponse.valid)
      } else {
        displayAlert('Invalid credentials', false)
        console.log('Invalid credentials')
      }
    }
  }

  const handleLogoutClick = () => {
    setSessionInfo(null)
  }

  const handleShowCompleteRegistrationModal = () => {
    console.log('Showing complete registration modal')
    setShowCompleteRegistrationModal(true)
  }

  const handleShowUsersModalClick = () => {
    setShowUsersModal(true)
  }

  const handleShowRegistrationsModalClick = () => {
    setShowRegistrationsModal(true)
  }

  const handleShowCreateRegistrationsModalClick = () => {
    setShowCreateRegistrationModal(true)
  }

  const handleCloseUsersModal = () => {
    setShowUsersModal(false)
  }

  const handleCloseRegistrationsModal = () => {
    setShowRegistrationsModal(false)
  }

  const handleCloseCreateRegistrationModal = (regResponse) => {
    setShowCreateRegistrationModal(false)
    if (regResponse) {
      displayAlert(regResponse.message, regResponse.valid)
      if (regResponse.valid) {
        setTimeout(() => displayAlert(`The registration key: ${regResponse.registration_info.key}.`, true), 4000)
      }
    }
  }

  const handleCloseCompleteRegistrationModal = (cmpResponse) => {
    setShowCompleteRegistrationModal(false)
    if (cmpResponse) {
      displayAlert(cmpResponse.message, cmpResponse.valid)
    }
  }

  const displayAlert = (message, valid) => {
    setAlertText(message)
    setShowAlert({ show: true, variant: valid ? 'primary' : 'danger' })
    setTimeout(() => setShowAlert({ show: false, variant: 'primary' }), 2000)
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Stack direction='horizontal' gap={3}>
            <Image src='book-shelf-svgrepo-com.svg' fluid style={{width: '90px', height: '90px'}}/>
            <h1>Reading History</h1>
            {sessionInfo && (
              <DropdownButton className='ms-auto' title={sessionInfo.email} variant='secondary'>
                {sessionInfo.role === 1 && (
                  <>
                    <Dropdown.Item onClick={handleShowUsersModalClick}>Users</Dropdown.Item>
                    <Dropdown.Item onClick={handleShowRegistrationsModalClick}>Registrations</Dropdown.Item>
                    <Dropdown.Item onClick={handleShowCreateRegistrationsModalClick}>Create Registration</Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
                <Dropdown.Item onClick={handleLogoutClick}>Logout</Dropdown.Item>
              </DropdownButton>
            )}
          </Stack>
        </Col>
      </Row>
      {!!sessionInfo && (
        <Row>
          <Col>
            <Tabs defaultActiveKey={'history'} onSelect={handleTabSelection}>
              <Tab eventKey='history' title='History'>
                <HistoryTab
                  open={historyTabOpen}
                  sessionInfo={sessionInfo} />
              </Tab>
              <Tab eventKey='books' title='Books'>
                <BooksTab
                  open={booksTabOpen} 
                  sessionInfo={sessionInfo} />
              </Tab>
              <Tab eventKey='authors' title='Authors'>
                <AuthorsTab
                  open={authorsTabOpen} 
                    sessionInfo={sessionInfo} />
              </Tab>
              <Tab eventKey='libby' title='Libby'>
                <LibbyTimelineTab
                  open={libbyTabOpen} 
                    sessionInfo={sessionInfo} />
              </Tab>
            </Tabs>
  
          </Col>
        </Row>
      )}
      {!sessionInfo && (
        <>
        <Row>
          <Col />
          <Col>
            <Login onClose={handleLoginModalClose} />
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col>
            <hr />
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col>
            <p className='text-center'>Need to complete your registration?</p>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col className='d-flex justify-content-md-center'>
            <Button variant='primary' onClick={handleShowCompleteRegistrationModal}>
              Complete Registration
            </Button>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col />
          <Col>
            <hr />
          </Col>
          <Col />
        </Row>
        </>
      )}
      <Row>
        <Col>
          <Alert variant={showAlert.variant} show={showAlert.show}>{alertText}</Alert>
          <UsersModal
            showIt={showUsersModal}
            sessionInfo={sessionInfo}
            onClose={handleCloseUsersModal} />
          <RegistrationsModal
            showIt={showRegistrationsModal}
            sessionInfo={sessionInfo}
            onClose={handleCloseRegistrationsModal} />
          <CreateRegistrationModal
            showIt={showCreateRegistrationModal}
            sessionInfo={sessionInfo}
            onClose={handleCloseCreateRegistrationModal} />
          <CompleteRegistrationModal
            showIt={showCompleteRegistrationModal}
            onClose={handleCloseCompleteRegistrationModal} />
        </Col>
      </Row>
    </Container>
  );
}

export default App;