import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Stack from 'react-bootstrap/Stack'
import DatePicker from 'react-datepicker'
import { makeRequest } from './ApiCalls'

function ReadingHistoryModal (props) {
  const { showIt, historyId, originalBookId, originalBookTitle, originalDateStarted, sessionInfo, onClose } = props

  const [dateStarted, setDateStarted] = useState(null)

  useEffect(() => {
    console.log(`BookID: ${originalBookId}`)
    if (showIt) {
      setDateStarted(originalDateStarted)
    }
  }, [showIt, originalBookId, originalDateStarted])

  const handleDateStartedChanged = (date) => {
    setDateStarted(date)
  }

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()

    const ds = dateStarted.toISOString().split('T')[0]

    const body = {
      user_id: sessionInfo.user_id,
      date_started: ds
    }

    if (historyId) {
      // We're editing a history entry
      makeRequest(
        'PUT',
        `api/books/${originalBookId}/readings/${historyId}`,
        sessionInfo.token,
        body
      ).then((resp) => {
        console.log(JSON.stringify(resp))
        onClose(true)
      })
    }
  }

  const handleClose = (evt) => {
    onClose(false)
  }

  return (
    <Modal show={showIt} onHide={handleClose}>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{historyId ? 'Edit History' : 'Add History'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack dir='vertical' gap={3}>
          <Form.Group>
            <Form.Label>Book</Form.Label>
            <Form.Control
              defaultValue={originalBookTitle}
              readOnly={true} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Date Started</Form.Label>
            <div>
            <DatePicker
              required
              selected={dateStarted}
              onChange={handleDateStartedChanged} />
            </div>
          </Form.Group>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='primary'
          >
            Update
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ReadingHistoryModal