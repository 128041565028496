import React, { useEffect, useState } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'

import { getBook } from './ApiCalls'

function BookDetailModal(props) {
  const { showIt, bookKey, onClose } = props

  const [bookData, setBookData] = useState({})
  const [hideSpinner, setHideSpinner] = useState(true)
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (showIt && bookKey != null) {
      setHideSpinner(false)
      getBook(bookKey)
        .then((resp) => {
          if (resp != null) {
            setBookData(resp.docs[0])
          }
          setHideSpinner(true)
        })
    }

  }, [showIt, bookKey])

  const handleClose = () => {
    onClose()
  }

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()

    onClose()
  }

  return (
    <Modal show={showIt} onHide={handleClose} size='xl'>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Raw Book Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
              { hideSpinner && (
                <div style={{ maxHeight: `${windowSize.innerHeight - 200}px`, overflow: 'auto'}}>
                  <pre
                    id='document-detail'
                    style={{ whiteSpace: 'pre', borderStyle: 'solid solid solid solid', borderWidth: '2px', borderColor: 'black', padding: '10px 10px 10px 10px', backgroundColor: 'white' }}
                  >
                    {JSON.stringify(bookData, null, 2)}
                  </pre>
                </div>
              )}
              { !hideSpinner && (
                <div style={{ textAlign: 'center' }}>
                <Spinner 
                  as='span' 
                  size='sm'
                  animation='border' 
                  hidden={hideSpinner}
                  aria-hidden="true"
                />
                </div>
              )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default BookDetailModal