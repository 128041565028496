export function buildUrl(baseUrl, filters, sortOrder, offset=0, pageSize=null, parms=[]) {

  const params = [...parms]

  if (offset >= 0 && pageSize) {
    params.push(`offset=${offset}&page_size=${pageSize}`)
  }

  if (sortOrder) {
    let order = sortOrder.ascending ? 'asc' : 'desc'
    params.push(`sort_column=${sortOrder.sortColumn}&sort_dir=${order}`)
  }

  const columns = []
  const criteria = []
  // eslint-disable-next-line
  filters.map((filter) => {
    columns.push(filter.filterColumn)
    criteria.push(filter.filterValue)
  })
  if (columns.length > 0) {
    params.push('filter_column=' + columns.join(','))
    params.push('filter_value=' + criteria.join(','))
  }
  if (params.length > 0) {
    baseUrl += '?' + params.join('&')
  }

  return baseUrl
}