import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Stack from 'react-bootstrap/Stack'

import { makeRequest } from './ApiCalls'

function UploadLibbyTimelineModal (props) {
  const { showIt, sessionInfo, onClose } = props

  const [addEnabled, setAddEnabled] = useState(false)
  const [timelineFile, setTimelineFile] = useState(null)

  useEffect(() => {
      setTimelineFile(null)
      setAddEnabled(false)
  }, [])

  const handleClose = () => {
    onClose(false)
  }

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()  

    const formData = new FormData()

    formData.append('user_id', sessionInfo.user_id)
    formData.append('file', timelineFile, timelineFile.name)

    makeRequest(
      'POST',
      'api/timelines',
      sessionInfo.token,
      formData,
      false
    ).then((resp) => {
      JSON.stringify(resp, null, 2)
      onClose(true)
    })
  }

  const handleFileSelection = (evt) => {
    if (evt.target.files.length > 0) {
      setTimelineFile(evt.target.files.item(0))
      setAddEnabled(true)
    }
  }

  return (
    <Modal show={showIt} onHide={handleClose} size='xl'>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Upload Libby Timeline</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack direction='vertical' gap={3}>
            <Form.Group>
              <Form.Label>Timeline File</Form.Label>
              <Form.Control 
                type='file' 
                className='mb-3 mt-3'
                onChange={handleFileSelection} />
            </Form.Group>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={!addEnabled}
            variant='primary'
          >
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default UploadLibbyTimelineModal
