import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'

import { makeRequest } from './ApiCalls'
import FilterModal from './FilterModal'
import AuthorsBooksModal from './AuthorsBooksModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import { buildUrl } from './urlUtils'

const PAGE_SIZE = 20

function AuthorsTab(props) {
  const { open, sessionInfo } = props

  const [authors, setAuthors] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [currentRow, setCurrentRow] = useState(null)
  const [filteredAuthorCount, setFilteredAuthorCount] = useState(0)
  const [filters, setFilters] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [showAuthorsBooksModal, setShowAuthorsBooksModal] = useState((false))
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [sortOrder, setSortOrder] = useState(null)
  const [totalAuthorCount, setTotalAuthorCount] = useState(0)
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMoreData = async () => {
      const url = buildUrl(
        `api/authors`,
        filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
        sortOrder,
        (currentPage + 1) * PAGE_SIZE,
        PAGE_SIZE
      )

      try {
        const newAuthors = await makeRequest(
          'GET',
          url,
          sessionInfo.token
        )
        
        newAuthors.length > 0 ? setHasMore(true) : setHasMore(false)
        setAuthors(prevAuthors => [...prevAuthors, ...newAuthors])
      } catch (e) {
        console.log(e)
      }

      setCurrentPage(prevPage => prevPage + 1)
  }

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        'api/authors',
        filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
        sortOrder,
        0,
        PAGE_SIZE
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      )
      .then((newAuthors) => {
        setAuthors(newAuthors)
        setHasMore(newAuthors.length > 0)
        setCurrentPage(0)
      })
    }
  }, [open, sortOrder, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        'api/authors',
        [],
        sortOrder,
        0,
        PAGE_SIZE,
        ['count']
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      )
      .then((authorCount) => {
        setTotalAuthorCount(authorCount.num_authors)
      })
    }
  }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      if (filters.length > 0) {
        const url = buildUrl(
          'api/authors',
          filters,
          sortOrder,
          0,
          PAGE_SIZE,
          ['count']
        )

        makeRequest(
          'GET',
          url,
          sessionInfo.token
        )
        .then((authorCount) => {
          setFilteredAuthorCount(authorCount.num_authors)
        })
      }
    }
  }, [open, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (historyRow) => {
    setCurrentRow(historyRow)
  }

  const handleColumnClick = (columnName) => {
    if (sortOrder) {
      if (sortOrder.sortColumn === columnName) {
        setSortOrder({ sortColumn: columnName, ascending: !sortOrder.ascending })
      } else {
        setSortOrder({ sortColumn: columnName, ascending: true })
      }
    } else {
      setSortOrder({ sortColumn: columnName, ascending: true })
    }
  }

  const handleCloseFilterModal = (filters) => {
    console.log(`Filters: ${filters}`)
    setShowFilterModal(false)
    setFilters(filters)
  }

  const handleFilterButtonClick = () => {
    setShowFilterModal(true)
  }

  const handleBooksButtonClick = () => {
    setShowAuthorsBooksModal(true)
  }

  const handleCloseAuthorsBooksModal = () => {
    setShowAuthorsBooksModal(false)
  }

  const readingCounts = () => {
    let label = `Total Authors: ${totalAuthorCount}`

    if (filters.length > 0) {
      label += ` Filtered Authors: ${filteredAuthorCount}`
    }

    return label
  }

  return (
    <Container fluid>
      <Row>
        <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Stack gap={3} direction='horizontal'>
            <Button className='ms-auth' onClick={handleFilterButtonClick}>Filter</Button>
            <Button disabled={!currentRow} className='ms-auth' onClick={handleBooksButtonClick}>Books</Button>
          </Stack>
        </Col>
        <Col className='d-flex' style={{height: '60px'}}>
          <p className='mx-auto my-auto'>{readingCounts()}</p>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col>
          <div id='author-div' style={{ maxHeight: `${windowSize.innerHeight - 200}px`, overflow: 'auto'}}>
            <InfiniteScroll
              dataLength={authors.length}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget='author-div'
            >
              <Table striped bordered hover>
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr>
                    <th onClick={evt => handleColumnClick('name')}>Name
                      {sortOrder && sortOrder.sortColumn === 'name' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'name' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th onClick={evt => handleColumnClick('author_books')}>Num Books
                      {sortOrder && sortOrder.sortColumn === 'author_books' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'author_books' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th onClick={evt => handleColumnClick('user_author_reads')}>You've Read
                      {sortOrder && sortOrder.sortColumn === 'author_books' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'user_author_reads' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th>Key</th>
                  </tr>
                </thead>
                <tbody>
                  {authors.map((row, index) => (
                    <tr 
                      key={index}
                      onClick={() => handleRowClick(row)}
                      className={currentRow && currentRow.id === row.id ? 'table-primary' : ''}
                    >
                      <td>{row.name}</td>
                      <td>{row.author_books}</td>
                      <td>{row.user_author_reads}</td>
                      <td>{row.ol_key}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <FilterModal
            showIt={showFilterModal}
            onClose={handleCloseFilterModal}
            suggestedFilters={filters}
            allowEmptyValue={false}
            filterColumns={[ 
              { columnName: 'name', title: 'Name' }
            ]} />
          <AuthorsBooksModal
            showIt={showAuthorsBooksModal}
            authorName={currentRow ? currentRow.name : ''}
            authorKey={currentRow ? currentRow.ol_key : ''}
            sessionInfo={sessionInfo}
            onClose={handleCloseAuthorsBooksModal} />
        </Col>
      </Row>
    </Container>
  )
}

export default AuthorsTab
