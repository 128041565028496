import React, { useEffect, useState } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'

import { makeRequest, searchBooks } from './ApiCalls'

function AdddBookModal (props) {
  const { showIt, sessionInfo, initialCriteria, onClose } = props

  const [ author, setAuthor] = useState('')
  const [ currentRow, setCurrentRow ] = useState(null)
  const [ doSearch, setDoSearch ] = useState(false)
  const [ searchCriteriaChanged, setSearchCriteriaChanged] = useState(false)
  const [ books, setBooks ] = useState({ docs: [] })
  const [ hideSpinner, setHideSpinner ] = useState(true)
  const [ isbn, setIsbn ] = useState('')
  const [ title, setTitle ] = useState('')
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    if (initialCriteria) {
      setTitle(initialCriteria.title ? initialCriteria.title : '')
      setAuthor(initialCriteria.author ? initialCriteria.author : '')
      setIsbn(initialCriteria.isbn ? initialCriteria.isbn : '')
    }
  }, [showIt, initialCriteria])

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showIt && doSearch) {
      if (author.length > 0 || title.length > 0 || isbn.length > 0) {
        setDoSearch(false)
        setCurrentRow(null)
        searchBooks(title, author, '', isbn)
        .then((books) => {
          setBooks(books)
          setHideSpinner(true)
        })
      }
    }
  }, [showIt, searchCriteriaChanged]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTitleChanged = (evt) => {
    setTitle(evt.target.value)
  }

  const handleAuthorChanged = (evt) => {
    setAuthor(evt.target.value)
  }

  const handleIsbnChanged = (evt) => {
    setIsbn(evt.target.value)
  }

  const handleSearchClicked = () => {
    setHideSpinner(false)
    setDoSearch(true)
    setSearchCriteriaChanged(!searchCriteriaChanged)
  }

  const handleClose = () => {
    setTitle('')
    setAuthor('')
    setIsbn('')
    setBooks({ docs: [] })
    setCurrentRow(null)
    onClose(currentRow)
  }

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()

    if (currentRow) {
      const authors = []
      let i = 0
      for (let name of currentRow.author_name) {
        authors.push({
          name: name,
          ol_key: currentRow.author_key[i]
        })
        i += 1
      }
      const isbns = []
      for (let isbn of currentRow.isbn) {
        isbns.push(isbn)
      }

      makeRequest(
        'POST',
        'api/books',
        sessionInfo.token,
        {
          title: currentRow.title,
          year_published: currentRow.publish_year.length > 0 ? currentRow.publish_year[0] : 0,
          ol_key: currentRow.key,
          authors: authors,
          isbns:isbns
        }
      ).then((resp) => {
        console.log(JSON.stringify(resp, null, 2))
        onClose(resp)
      })
    } else {
      onClose(null)
    }

    setTitle('')
    setAuthor('')
    setIsbn('')
    setCurrentRow(null)
    setBooks({ docs: [] })
  }

  const handleRowClick = (row) => {
    setCurrentRow(row)
  }

  return (
    <Modal show={showIt} onHide={handleClose} size='xl'>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Add Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
                <Stack direction='horizontal' gap={3}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control defaultValue={title} onChange={handleTitleChanged} />
                  <Form.Label>Author</Form.Label>
                  <Form.Control defaultValue={author} onChange={handleAuthorChanged} />
                  <Form.Label>ISBN</Form.Label>
                  <Form.Control defaultValue={isbn} onChange={handleIsbnChanged} />
                  <Button variant='primary' onClick={handleSearchClicked} disabled={!hideSpinner}>
                    <Spinner 
                      as='span' 
                      size='sm'
                      animation='border' 
                      hidden={hideSpinner}
                      aria-hidden="true"
                    />
                    Search
                  </Button>
                </Stack>
              </Col>
            </Row>
            <Row style={{ margin: "20px" }}>
              <Col>
                <div style={{ maxHeight: `${windowSize.innerHeight - 400}px`, overflow: 'auto'}}>
                  <Table striped bordered hover>
                    <thead style={{ position: 'sticky', top: '0' }}>
                      <tr>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Published</th>
                        <th>Isbn</th>
                        <th>Key</th>
                      </tr>
                    </thead>
                    <tbody>
                      {books.docs.map((row, index) => (
                        <tr 
                          key={index}
                          onClick={() => handleRowClick(row)}
                          className={currentRow && currentRow.key === row.key ? 'table-primary' : ''}
                        >
                          <td>{row.title}</td>
                          <td>{row.author_name ? row.author_name.join(',') : ''}</td>
                          <td>{row.publish_year ? row.publish_year.length > 0 ? row.publish_year[0] : '' : '' }</td>
                          <td>{row.isbn ? row.isbn.join(',') : ''}</td>
                          <td>{row.key}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            disabled={!currentRow}
            variant='primary'
          >
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>

  )
}

export default AdddBookModal