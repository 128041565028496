import React, { useEffect, useState } from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'

function FilterModal(props) {
  const { showIt, suggestedFilters, filterColumns, allowEmptyValue, onClose } = props

  const [filters, setFilters] = useState(suggestedFilters)
  const [filterColumn, setFilterColumn] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const [filterValid, setFilterValid] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [message, setMessage] = useState('')
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    setMessage('')
    setFilterColumn('')
    setFilterValue('')
    setFilterValid(false)
  }, [showIt])

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    // setFilters([])
    onClose([])
  }

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault()
    
    // setFilters([])
    onClose(filters)
  }

  const handleFilterColumnChange = (evt) => {
    const colName = evt.target.value
    setMessage('')
    setFilterColumn(colName)
    setFilterValid(colName.length > 0 && (allowEmptyValue || filterValue.length > 0))
  }

  const handleFilterValueChange = (evt) => {
    const colValue = evt.target.value
    setFilterValue(colValue)
    setFilterValid(filterColumn.length > 0 && (allowEmptyValue || colValue.length > 0))
  }

  const handleAddFilterClicked = (evt) => {
    if (filterValid) {
      console.log(`Filters: ${JSON.stringify(filters)}`)
      const existing = filters.filter(o => o.filterColumn !== filterColumn)
      setFilters(existing.concat([{
        filterColumn: filterColumn,
        filterValue: filterValue
      }]))
      setFilterColumn('')
      setFilterValue('')
      setFilterValid(false)
      setMessage('')
    }
  }

  const handleRowClick = (filter) => {
    setSelectedFilter(filter)

    // Also, set the controls to allow editing of the filter
    setFilterColumn(filter.filterColumn)
    setFilterValue(filter.filterValue)
    setFilterValid(filterColumn.length > 0 && (allowEmptyValue || filterValue.length > 0))
  }

  const handleDeleteFilterClicked = () => {
    if (selectedFilter) {
      setFilters(filters.filter(item => item.filterColumn !== selectedFilter.filterColumn))
      setSelectedFilter(null)
    }
  }

  const handleClearFilterClicked = () => {
    setFilters([])
    setSelectedFilter(null)
    setFilterColumn('')
    setFilterValue('')
    setFilterValid('')
  }

  return (
    <Modal show={showIt} onHide={handleClose} size='xl'>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title>Set Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
                <Stack direction='horizontal' gap={3}>
                  <Form.Label>Column</Form.Label>
                  <Form.Select
                    required
                    id='column-select'
                    name='column'
                    value={filterColumn ? filterColumn : ''}
                    onChange={handleFilterColumnChange}
                  >
                    <option value=''>Select Column</option>
                    {filterColumns.map(filterColumn => (
                      <option value={filterColumn.columnName} key={filterColumn.columnName}>{filterColumn.title}</option>
                    ))}
                  </Form.Select>
                  <Form.Label>Value</Form.Label>
                  <Form.Control value={filterValue ? filterValue : ''} onChange={handleFilterValueChange} />
                  <Button disabled={!filterValid} variant='primary' onClick={handleAddFilterClicked}>Add</Button>
                  <Button disabled={!selectedFilter} variant='danger' onClick={handleDeleteFilterClicked}>Delete</Button>
                  <Button variant='danger' onClick={handleClearFilterClicked}>Clear</Button>
                </Stack>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{message}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ maxHeight: `${windowSize.innerHeight - 400}px`, overflow: 'auto'}}>
                  <Table striped bordered hover>
                    <thead style={{ position: 'sticky', top: '0' }}>
                      <tr>
                        <th>Column</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filters.map((row, index) => (
                        <tr 
                          key={index}
                          onClick={() => handleRowClick(row)}
                          className={selectedFilter && selectedFilter.filterColumn === row.filterColumn ? 'table-primary' : ''}
                        >
                          <td>{row.filterColumn}</td>
                          <td>{row.filterValue}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='primary'
          >
            Filter
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default FilterModal