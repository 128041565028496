import React, { useEffect, useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Overlay from 'react-bootstrap/Overlay'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'

import { makeRequest } from './ApiCalls'
import AdddBookModal from './AddBookModal'
import FilterModal from './FilterModal'
import BookDetailModal from './BookDetailModal'
import ReadBookModal from './ReadBookModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import { buildUrl } from './urlUtils'

const PAGE_SIZE = 20

function BooksTab(props) {
  const { open, sessionInfo } = props

  const popoverContainerRef = useRef(null)
  const popoverTargetRef = useRef(null)

  const [bookAdded, setBookAdded] = useState(false)
  const [books, setBooks] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [currentRow, setCurrentRow] = useState(null)
  const [filteredBookCount, setFilteredBookCount] = useState(0)
  const [filters, setFilters] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [imageUrl, setImageUrl] = useState(null)
  const [showReadBookModal, setShowReadBookModal] = useState(false)
  const [showBookDetailModal, setShowBookDetailModal] = useState(false)
  const [showCoverArt, setShowCoverArt] = useState(false)
  const [showAddBookModal, setShowAddBookModal] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [sortOrder, setSortOrder] = useState(null)
  const [totalBookCount, setTotalBookCount] = useState(0)
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMoreData = async () => {
      const url = buildUrl(
        `api/books`,
        filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
        sortOrder,
        (currentPage + 1) * PAGE_SIZE,
        PAGE_SIZE
      )

      try {
        const newBooks = await makeRequest(
          'GET',
          url,
          sessionInfo.token
        )
        
        newBooks.length > 0 ? setHasMore(true) : setHasMore(false)
        setBooks(prevBooks => [...prevBooks, ...newBooks])
      } catch (e) {
        console.log(e)
      }

      setCurrentPage(prevPage => prevPage + 1)
  }

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        'api/books',
        filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
        sortOrder,
        0,
        PAGE_SIZE
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      )
      .then((books) => {
        setBooks(books)
        setHasMore(books.length > 0)
        setCurrentPage(0)
      })
    }
  }, [open, bookAdded, sortOrder, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        'api/books',
        [],
        sortOrder,
        0,
        PAGE_SIZE,
        ['count']
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      )
      .then(bookCount => setTotalBookCount(bookCount.num_books))
    }
  }, [open, bookAdded]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      if (filters.length > 0) {
        const url = buildUrl(
          'api/books',
          filters,
          sortOrder,
          0,
          PAGE_SIZE,
          ['count']
        )

        makeRequest(
          'GET',
          url,
          sessionInfo.token
        )
        .then(bookCount => setFilteredBookCount(bookCount.num_books))
      }
    }
  }, [open, bookAdded, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (historyRow) => {
    setCurrentRow(historyRow)
  }

  const handleAddBookModalClose = (adding) => {
    setShowAddBookModal(false)
    if (adding) {
      setBookAdded(!bookAdded)
    }
  }

  const handleAddButtonClick = (evt) => {
    setShowAddBookModal(true)
  }

  const handleReadButtonClick = (evt) => {
    setShowReadBookModal(true)
  }

  const handleReadBookModalClose = (reading) => {
    setShowReadBookModal(false)
    if (reading) {
      setBookAdded(!bookAdded)
    }
  }

  const handleColumnClick = (columnName) => {
    if (sortOrder) {
      if (sortOrder.sortColumn === columnName) {
        setSortOrder({ sortColumn: columnName, ascending: !sortOrder.ascending })
      } else {
        setSortOrder({ sortColumn: columnName, ascending: true })
      }
    } else {
      setSortOrder({ sortColumn: columnName, ascending: true })
    }
  }

  const handleCloseFilterModal = (filters) => {
    console.log(`Filters: ${filters}`)
    setShowFilterModal(false)
    setFilters(filters)
  }

  const handleFilterButtonClick = () => {
    setShowFilterModal(true)
  }

  const handleDetailButtonClick = () => {
    if (currentRow) {
      setShowBookDetailModal(true)
    }
  }

  const handleCloseBookDetailModal = () => {
    setShowBookDetailModal(false)
  }

  const handleCoverImageCloseClick = (evt) => {
    setShowCoverArt(false)
  }

  const handleMouseOverCoverArt = (evt, image_url) => {
    if (evt) evt.preventDefault()

    setImageUrl(image_url)

    if (image_url) {
      setShowCoverArt(!showCoverArt)
    }
  }

  const handleMouseLeaveCoverArt = (evt) => {
    if (evt) evt.preventDefault()

    setShowCoverArt(!showCoverArt)

    setImageUrl(null)
  }

  const readingCounts = () => {
    let label = `Total Books: ${totalBookCount}`

    if (filters.length > 0) {
      label += ` Filtered Books: ${filteredBookCount}`
    }

    return label
  }

  return (
    <Container fluid ref={popoverContainerRef}>
      <Row>
        <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Stack gap={3} direction='horizontal'>
            <Button className='ms-auth' onClick={handleFilterButtonClick}>Filter</Button>
            <Button disabled={!currentRow} className='ms-auth' onClick={handleDetailButtonClick}>Detail</Button>
          </Stack>
        </Col>
        <Col ref={popoverTargetRef} className='d-flex' style={{height: '60px'}}>
          <p className='mx-auto my-auto'>{readingCounts()}</p>
        </Col>
        <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Stack gap={3} direction='horizontal'>
            <Button className='ms-auto' onClick={handleAddButtonClick}>
              Add
            </Button>
            <Button 
              disabled={!currentRow}
              onClick={handleReadButtonClick}>
              Read
            </Button>
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id='book-div' style={{ maxHeight: `${windowSize.innerHeight - 200}px`, overflow: 'auto'}}>
            <InfiniteScroll
              dataLength={books.length}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget='book-div'
            >
              <Table striped bordered hover id='book-table'>
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr>
                    <th onClick={evt => handleColumnClick('title')}>Title
                      {sortOrder && sortOrder.sortColumn === 'title' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'title' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th onClick={evt => handleColumnClick('authors')}>Authors
                      {sortOrder && sortOrder.sortColumn === 'authors' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'authors' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th onClick={evt => handleColumnClick('year_published')}>First Published
                      {sortOrder && sortOrder.sortColumn === 'year_published' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'year_published' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th onClick={evt => handleColumnClick('num_reads')}>Num Reads
                      {sortOrder && sortOrder.sortColumn === 'num_reads' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'num_reads' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th onClick={evt => handleColumnClick('user_book_reads')}>Your Reads
                      {sortOrder && sortOrder.sortColumn === 'user_book_reads' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'user_book_reads' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th>
                      Cover
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {books.map((row, index) => (
                    <tr 
                      key={index}
                      onClick={() => handleRowClick(row)}
                      className={currentRow && currentRow.id === row.id ? 'table-primary' : ''}
                    >
                      <td>{row.title}</td>
                      <td>{row.authors}</td>
                      <td>{row.year_published}</td>
                      <td>{row.num_reads}</td>
                      <td>{row.user_book_reads}</td>
                      <td>
                        {row.cover_image && (
                          <Button 
                            variant='link' 
                            onMouseLeave={evt => handleMouseLeaveCoverArt(evt)} 
                            onMouseOver={evt => handleMouseOverCoverArt(evt, row.cover_image)}
                          >
                            View
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
            <Overlay
              show={showCoverArt}
              target={popoverTargetRef}
              placement='auto'
              container={popoverContainerRef}
              rootClose={true}
              rootCloseEvent='click'
              containerPadding={20}>
              <Image src={imageUrl} onClick={handleCoverImageCloseClick}/>
            </Overlay>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdddBookModal
            showIt={showAddBookModal}
            sessionInfo={sessionInfo}
            onClose={handleAddBookModalClose} />
          <FilterModal
            showIt={showFilterModal}
            onClose={handleCloseFilterModal}
            suggestedFilters={filters}
            allowEmptyValue={false}
            filterColumns={[ 
              { columnName: 'title', title: 'Title' }, 
              { columnName: 'authors', title: 'Authors' }, 
              { columnName: 'year_published', title: 'Year Published' }, 
            ]} />
          <BookDetailModal
            showIt={showBookDetailModal}
            bookKey={currentRow ? currentRow.ol_key : null}
            onClose={handleCloseBookDetailModal} />
          <ReadBookModal
            showIt={showReadBookModal}
            book={currentRow}
            startDate={new Date()}
            sessionInfo={sessionInfo}
            onClose={handleReadBookModalClose} />
        </Col>
      </Row>
    </Container>
  )
}

export default BooksTab
