// See .env.local for the value
const OL_BASE_URL = process.env.REACT_APP_OPENLIBRARY_URL

export async function makeRequest(method, uri, jwtToken=null, messageBody=null, jsonData=true) {

  const headers = { }

  if (jwtToken) {
    headers['Authorization'] = `Bearer ${jwtToken}`
  }

  if (messageBody) {
    if (jsonData) {
      headers['Content-Type'] = 'application/json'
    }
  }

  const resp = await fetch(
    `/${uri}`,
    {
      method: method,
      body: messageBody ? jsonData ? JSON.stringify(messageBody) : messageBody : null,
      headers: headers
    }
  )

  if (resp.ok) {
    return resp.json()
  } else {
    console.log(`Error ${resp.status}: ${resp.statusText}`)
    return null
  }
}

const build_search_url = (criteria, offset=null, limit=null, fields=null) => {

  let search_url = OL_BASE_URL + '?q=' + encode_search_parameters(criteria)

  if (!!offset) {
    search_url += `&offset=${offset}`
  }
  if (!!limit) {
    search_url += `&limit=${limit}`
  }
  if (!!fields && fields.length > 0) {
    search_url += '&fields=' + fields.join(',')
  }

  return search_url
}

const quote_plus = (str_to_encode) => {
  return encodeURIComponent(str_to_encode).replaceAll('%20', '+')
}

const encode_search_parameters = (criteria) => {
    const key_values = []

    for (let [key, value] of Object.entries(criteria)) {
      value = quote_plus(value)
      key_values.push(`${key}:${value}`)
    }

    return key_values.join('+AND+')
}



export async function searchBooks(title, author, key='', isbn='', offset=null, limit=null) {
  const searchCriteria = { }
  const fields = [
      'author_name',
      'author_key',
      'key',
      'title',
      'publish_year',
      'isbn'
    ]

  if (isbn) {
    searchCriteria['isbn'] = isbn
  } else {
    if (title) searchCriteria['title'] = title
    if (author) searchCriteria['author'] = author
    if (key) searchCriteria['key'] = key
  }

  const url = build_search_url(searchCriteria, offset, limit, fields)

  const resp = await fetch(url)

  if (resp.ok) {
    return resp.json()
  } else {
    console.log(`Error ${resp.status}: ${resp.statusText}`)
    return null
  }

}

export async function getBook(key) {
  const getUrl = `${OL_BASE_URL}?q=key:${key}`
  const resp = await fetch(getUrl)

  if (resp.ok) {
    return resp.json()
  } else {
    console.log(`Error ${resp.status}: ${resp.statusText}`)
    return null
  }
}

export async function getAuthorsBooks(authorKey) {
  const fields = [
      'author_name',
      'author_key',
      'key',
      'title',
      'publish_year'
    ]

  const getUrl = `${OL_BASE_URL}?q=author_key:${authorKey}&fields=${fields.join(',')}`
  const resp = await fetch(getUrl)

  if (resp.ok) {
    return resp.json()
  } else {
    console.log(`Error ${resp.status}: ${resp.statusText}`)
    return null
  }
}