import React, { useEffect, useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Overlay from 'react-bootstrap/Overlay'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'

import { makeRequest } from './ApiCalls'
import ReadingHistoryModal from './ReadingHistoryModal'
import DeleteReadingHistoryModal from './DeleteReadingHistoryModal'
import FilterModal from './FilterModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import { buildUrl } from './urlUtils'

const PAGE_SIZE = 20

function HistoryTab(props) {
  const { open, sessionInfo } = props

  const popoverContainerRef = useRef(null)
  const popoverTargetRef = useRef(null)

  const [currentRow, setCurrentRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [filteredReadingHistoryCount, setFilteredReadingHistoryCount] = useState(0)
  const [filters, setFilters] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [imageUrl, setImageUrl] = useState(null)
  const [readingHistory, setReadingHistory] = useState([])
  const [readingHistoryChanged, setReadingHistoryChanged] = useState(false)
  const [showCoverArt, setShowCoverArt] = useState(false)
  const [showDeleteReadingHistoryModal, setShowDeleteReadingHistoryModal] = useState(false)
  const [showReadingHistoryModal, setShowReadingHistoryModal] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [sortOrder, setSortOrder] = useState(null)
  const [totalReadingHistoryCount, setTotalReadingHistoryCount] = useState(0)
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  const fetchMoreData = async () => {
      const url = buildUrl(
        `api/reading_history`,
        filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
        sortOrder,
        (currentPage + 1) * PAGE_SIZE,
        PAGE_SIZE
      )

      try {
        const newHistry = await makeRequest(
          'GET',
          url,
          sessionInfo.token
        )
        
        newHistry.length > 0 ? setHasMore(true) : setHasMore(false)
        setReadingHistory(prevHistory => [...prevHistory, ...newHistry])
      } catch (e) {
        console.log(e)
      }

      setCurrentPage(prevPage => prevPage + 1)
  }

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        'api/reading_history',
        [{ filterColumn: 'user_id', filterValue: sessionInfo.user_id }],
        sortOrder,
        0,
        PAGE_SIZE,
        ['count']
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      ).then((readingCount) => {
        setTotalReadingHistoryCount(readingCount.num_readings)
      })
    }
  }, [open, readingHistoryChanged]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      if (filters.length > 0) {
        const url = buildUrl(
          'api/reading_history',
          filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
          sortOrder,
          0,
          PAGE_SIZE,
          ['count']
        )

        makeRequest(
          'GET',
          url,
          sessionInfo.token
        ).then((readingCount) => {
          setFilteredReadingHistoryCount(readingCount.num_readings)
        })
      }
    }
  }, [open, readingHistoryChanged, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        'api/reading_history',
        filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
        sortOrder,
        0,
        PAGE_SIZE
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      ).then((history) => {
        setReadingHistory(history)
        setHasMore(history.length > 0)
        setCurrentPage(0)
      })
    }
  }, [open, readingHistoryChanged, sortOrder, filters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (historyRow) => {
    setCurrentRow(historyRow)
  }

  const handleEditButtonClick = () => {
    setShowReadingHistoryModal(true)
  }

  const handleDeleteButtonClick = () => {
    setShowDeleteReadingHistoryModal(true)
  }

  const handleCloseReadingHistoryModal = (edited) => {
    console.log(`Current Row: ${JSON.stringify(currentRow, null, 2)}`)
    setShowReadingHistoryModal(false)
    if (edited) {
      setReadingHistoryChanged(!readingHistoryChanged)
    }
  }

  const handleCloseDeleteReadingHistoryModal = (edited) => {
    setShowDeleteReadingHistoryModal(false)
    if (edited) {
      setReadingHistoryChanged(!readingHistoryChanged)
    }
  }

  const handleColumnClick = (columnName) => {
    if (sortOrder) {
      if (sortOrder.sortColumn === columnName) {
        setSortOrder({ sortColumn: columnName, ascending: !sortOrder.ascending })
      } else {
        setSortOrder({ sortColumn: columnName, ascending: true })
      }
    } else {
      setSortOrder({ sortColumn: columnName, ascending: true })
    }
  }

  const handleCloseFilterModal = (filters) => {
    console.log(`Filters: ${filters}`)
    setShowFilterModal(false)
    setFilters(filters)
  }

  const handleFilterButtonClick = () => {
    setShowFilterModal(true)
  }

  const handleCoverImageCloseClick = (evt) => {
    setShowCoverArt(false)
  }

  const handleMouseOverCoverArt = (evt, image_url) => {
    if (evt) evt.preventDefault()

    setImageUrl(image_url)

    if (image_url) {
      setShowCoverArt(!showCoverArt)
    }
  }

  const handleMouseLeaveCoverArt = (evt) => {
    if (evt) evt.preventDefault()

    setShowCoverArt(!showCoverArt)

    setImageUrl(null)
  }

  const readingCounts = () => {
    let label = `Total Readings: ${totalReadingHistoryCount}`

    if (filters.length > 0) {
      label += ` Filtered Readings: ${filteredReadingHistoryCount}`
    }

    return label
  }

  return (
    <Container fluid ref={popoverContainerRef}>
      <Row>
        <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Stack gap={3} direction='horizontal'>
            <Button className='ms-auth' onClick={handleFilterButtonClick}>Filter</Button>
          </Stack>
        </Col>
        <Col ref={popoverTargetRef} className='d-flex' style={{height: '60px'}}>
          <p className='mx-auto my-auto'>{readingCounts()}</p>
        </Col>
        <Col  style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Stack gap={3} direction='horizontal'>
            <Button className='ms-auto' onClick={handleEditButtonClick} disabled={!currentRow}>
              Edit
            </Button>
            <Button onClick={handleDeleteButtonClick} disabled={!currentRow} variant='danger'>
              Delete
            </Button>
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id='history-div' style={{ maxHeight: `${windowSize.innerHeight - 200}px`, overflow: 'auto'}}>
            <InfiniteScroll
              dataLength={readingHistory.length}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget='history-div'
            >
              <Table striped bordered hover id='history-table'>
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr>
                    <th onClick={evt => handleColumnClick('title')}>Title
                      {sortOrder && sortOrder.sortColumn === 'title' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'title' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th onClick={evt => handleColumnClick('authors')}>Authors
                      {sortOrder && sortOrder.sortColumn === 'authors' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'authors' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th onClick={evt => handleColumnClick('date_started')}>Date Started
                      {sortOrder && sortOrder.sortColumn === 'date_started' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'date_started' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th>
                      Cover
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {readingHistory.map((row, index) => (
                    <tr
                      key={index} 
                      onClick={() => handleRowClick(row)}
                      className={currentRow && currentRow.id === row.id ? 'table-primary' : ''}
                    >
                      <td>{row.title}</td>
                      <td>{row.authors}</td>
                      <td>{row.date_started}</td>
                      <td>
                        {row.cover_image && (
                          <Button 
                            variant='link' 
                            onMouseLeave={evt => handleMouseLeaveCoverArt(evt)} 
                            onMouseOver={evt => handleMouseOverCoverArt(evt, row.cover_image)}
                          >
                            View
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
            <Overlay
              show={showCoverArt}
              target={popoverTargetRef}
              placement='auto'
              container={popoverContainerRef}
              rootClose={true}
              rootCloseEvent='click'
              containerPadding={20}>
              <Image src={imageUrl} onClick={handleCoverImageCloseClick}/>
            </Overlay>
 
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReadingHistoryModal
            showIt={showReadingHistoryModal}
            historyId={currentRow ? currentRow.id : null}
            originalBookId={currentRow ? currentRow.book_id : ''}
            originalBookTitle={currentRow ? currentRow.title : ''}
            originalDateStarted={currentRow ? new Date(currentRow.date_started + 'T00:00') : new Date()}
            sessionInfo={sessionInfo}
            onClose={handleCloseReadingHistoryModal} />
          <DeleteReadingHistoryModal
            showIt={showDeleteReadingHistoryModal}
            readingHistory={currentRow}
            sessionInfo={sessionInfo}
            onClose={handleCloseDeleteReadingHistoryModal} />
          <FilterModal
            showIt={showFilterModal}
            onClose={handleCloseFilterModal}
            suggestedFilters={filters}
            allowEmptyValue={false}
            filterColumns={[ 
              { columnName: 'title', title: 'Title' }, 
              { columnName: 'authors', title: 'Authors' }, 
              { columnName: 'year', title: 'Year' }, 
              { columnName: 'month', title: 'Month' }
            ]} />
        </Col>
      </Row>
    </Container>
  )
}

export default HistoryTab