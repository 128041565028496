import React, { useEffect, useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Overlay from 'react-bootstrap/Overlay'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'

import { makeRequest } from './ApiCalls'
import AdddBookModal from './AddBookModal'
import FilterModal from './FilterModal'
import UploadLibbyTimelineModal from './UploadLibbyTimelineModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import { buildUrl } from './urlUtils'

const PAGE_SIZE = 20

function LibbyTimelineTab(props) {
  const { open, sessionInfo } = props

  const popoverContainerRef = useRef(null)
  const popoverTargetRef = useRef(null)

  const [events, setEvents] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [currentRow, setCurrentRow] = useState(null)
  const [filteredTimelineEventCount, setFilteredTimelineEventCount] = useState(0)
  const [filters, setFilters] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [imageUrl, setImageUrl] = useState(null)
  const [showCoverArt, setShowCoverArt] = useState(false)
  const [showAddBookModal, setShowAddBookModal] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [showUploadLibbyTimelineModal, setShowUploadLibbyTimelineModal] = useState(false)
  const [sortOrder, setSortOrder] = useState(null)
  const [totalTimelineEventCount, setTotalTimelineEventCount] = useState(0)
  const [updatedEvent, setUpdatedEvent] = useState(false)
  const [windowSize, setWindowSize] = useState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize({ innerWidth: window.innerWidth, innerHeight: window.innerHeight })
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMoreData = async () => {
      const url = buildUrl(
        `api/timelines`,
        filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
        sortOrder,
        (currentPage + 1) * PAGE_SIZE,
        PAGE_SIZE
      )

      try {
        const newEvents = await makeRequest(
          'GET',
          url,
          sessionInfo.token
        )
        
        newEvents.length > 0 ? setHasMore(true) : setHasMore(false)
        setEvents(prevEvents => [...prevEvents, ...newEvents])
      } catch (e) {
        console.log(e)
      }

      setCurrentPage(prevPage => prevPage + 1)
  }

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        `api/timelines`,
        filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
        sortOrder,
        0,
        PAGE_SIZE
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      )
      .then((newEvents) => {
        setEvents(newEvents)
        setHasMore(newEvents.length > 0)
        setCurrentPage(0)
      })
    }
  }, [open, sortOrder, filters, updatedEvent]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      const url = buildUrl(
        `api/timelines`,
        [{ filterColumn: 'user_id', filterValue: sessionInfo.user_id }],
        sortOrder,
        0,
        PAGE_SIZE,
        ['count']
      )

      makeRequest(
        'GET',
        url,
        sessionInfo.token
      )
      .then((events) => {
        setTotalTimelineEventCount(events.num_events)
      })
    }
  }, [open, updatedEvent]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      if (filters.length > 0) {
        const url = buildUrl(
          `api/timelines`,
          filters.concat({ filterColumn: 'user_id', filterValue: sessionInfo.user_id }),
          sortOrder,
          0,
          PAGE_SIZE,
          ['count']
        )

        makeRequest(
          'GET',
          url,
          sessionInfo.token
        )
        .then((events) => {
          setFilteredTimelineEventCount(events.num_events)
        })
      }
    }
  }, [open, filters, updatedEvent]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRowClick = (historyRow) => {
    setCurrentRow(historyRow)
  }

  const handleColumnClick = (columnName) => {
    if (sortOrder) {
      if (sortOrder.sortColumn === columnName) {
        setSortOrder({ sortColumn: columnName, ascending: !sortOrder.ascending })
      } else {
        setSortOrder({ sortColumn: columnName, ascending: true })
      }
    } else {
      setSortOrder({ sortColumn: columnName, ascending: true })
    }
  }

  const handleCloseFilterModal = (filters) => {
    console.log(`Filters: ${filters}`)
    setShowFilterModal(false)
    setFilters(filters)
  }

  const handleFilterButtonClick = () => {
    setShowFilterModal(true)
  }

  const handleAddButtonClick = () => {
    setShowAddBookModal(true)
  }

  const handleAddBookModalClose = (bookInfo) => {
    setShowAddBookModal(false)
    if (bookInfo) {
      // TODO: Now that the book has been added, take care of the
      // reading...
      console.log(JSON.stringify(bookInfo, null, 2))
      if (currentRow) {
        console.log(JSON.stringify(currentRow, null, 2))
        makeRequest(
          'POST',
          `api/books/${bookInfo.id}/readings`,
          sessionInfo.token,
          {
            user_id: sessionInfo.user_id,
            date_started: currentRow.borrowed
          }
        ).then((resp) => {
          console.log(JSON.stringify(resp, null, 2))
          
          // Finally, update the timeline object with the 
          // Book ID and the reading ID
          makeRequest(
            'PUT',
            `api/timelines/${currentRow.id}`,
            sessionInfo.token,
            {
              book_id: bookInfo.id,
              reading_id: resp.id
            }
          ).then((resp) => {
            console.log(JSON.stringify(resp, null, 2))
            setCurrentRow(null)
            setUpdatedEvent(!updatedEvent)
          })
        })

      }
    }
  }

  const handleReadingButtonClick = () => {
    console.log('Adding a reading for this entry')
    if (currentRow) {
      makeRequest(
        'POST',
        `api/books/${currentRow.book_id}/readings`,
        sessionInfo.token,
        {
          user_id: sessionInfo.user_id,
          date_started: currentRow.borrowed
        }
      ).then((resp) => {
        console.log(JSON.stringify(resp, null, 2))
        makeRequest(
          'PUT',
          `api/timelines/${currentRow.id}`,
          sessionInfo.token,
          {
            book_id: currentRow.book_id,
            reading_id: resp.id
          }
        ).then((resp) => {
          console.log(JSON.stringify(resp, null, 2))
          setCurrentRow(null)
          setUpdatedEvent(!updatedEvent)
        })
      })
    }
  }

  const handleCoverImageCloseClick = (evt) => {
    setShowCoverArt(false)
  }

  const handleMouseOverCoverArt = (evt, image_url) => {
    if (evt) evt.preventDefault()

    setImageUrl(image_url)

    if (image_url) {
      setShowCoverArt(!showCoverArt)
    }
  }

  const handleMouseLeaveCoverArt = (evt) => {
    if (evt) evt.preventDefault()

    setShowCoverArt(!showCoverArt)

    setImageUrl(null)
  }

  const handleUploadButtonClick = (evt) => {
    if (evt) evt.preventDefault()

    console.log('Clicked upload button')
    setShowUploadLibbyTimelineModal(true)
  }

  const handleCloseUploadLibbyTimelineModal = (loadedFile) => {
    if (loadedFile) setUpdatedEvent(true)

    setShowUploadLibbyTimelineModal(false)
  }

  const readingCounts = () => {
    let label = `Total Loans: ${totalTimelineEventCount}`

    if (filters.length > 0) {
      label += ` Filtered Loans: ${filteredTimelineEventCount}`
    }

    return label
  }

  return (
    <Container fluid ref={popoverContainerRef}>
      <Row>
        <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Stack gap={3} direction='horizontal'>
            <Button className='ms-auth' onClick={handleFilterButtonClick}>Filter</Button>
          </Stack>
        </Col>
        <Col ref={popoverTargetRef} className='d-flex' style={{height: '60px'}}>
          <p className='mx-auto my-auto'>{readingCounts()}</p>
        </Col>
        <Col style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <Stack gap={3} direction='horizontal'>
            <Button disabled={!currentRow || currentRow.book_id} className='ms-auto' onClick={handleAddButtonClick}>
              Add
            </Button>
            <Button disabled={!currentRow || !currentRow.book_id || currentRow.reading_id } onClick={handleReadingButtonClick}>
              Reading
            </Button>
            <Button onClick={handleUploadButtonClick}>
              Upload
            </Button>
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id='libby-table' style={{ maxHeight: `${windowSize.innerHeight - 200}px`, overflow: 'auto'}}>
            <InfiniteScroll
              dataLength={events.length}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget='libby-table'
            >
              <Table striped bordered hover>
                <thead style={{ position: 'sticky', top: '0' }}>
                  <tr>
                    <th onClick={evt => handleColumnClick('title')}>Title
                      {sortOrder && sortOrder.sortColumn === 'title' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'title' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th onClick={evt => handleColumnClick('author')}>Author
                      {sortOrder && sortOrder.sortColumn === 'author' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'author' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th>ISBN</th>
                    <th onClick={evt => handleColumnClick('borrowed')}>Borrowed
                      {sortOrder && sortOrder.sortColumn === 'borrowed' && sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                        </svg>   
                      )}
                      {sortOrder && sortOrder.sortColumn === 'borrowed' && !sortOrder.ascending && (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>                                            
                        </svg>
                      )}
                    </th>
                    <th>Book</th>
                    <th>Reading</th>
                    <th>
                      Cover
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((row, index) => (
                    <tr 
                      key={index}
                      onClick={() => handleRowClick(row)}
                      className={currentRow && currentRow.id === row.id ? 'table-primary' : ''}
                    >
                      <td>{row.title}</td>
                      <td>{row.author}</td>
                      <td>{row.isbn}</td>
                      <td>{row.borrowed}</td>
                      <td>{row.book_id ? 'X' : ''}</td>
                      <td>{row.reading_id ? 'X' : ''}</td>
                      <td>
                        {row.cover_image && (
                          <Button 
                            variant='link' 
                            onMouseLeave={evt => handleMouseLeaveCoverArt(evt)} 
                            onMouseOver={evt => handleMouseOverCoverArt(evt, row.cover_image)}
                          >
                            View
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </InfiniteScroll>
            <Overlay
              show={showCoverArt}
              target={popoverTargetRef}
              placement='auto'
              container={popoverContainerRef}
              rootClose={true}
              rootCloseEvent='click'
              containerPadding={20}>
              <Image src={imageUrl} onClick={handleCoverImageCloseClick}/>
            </Overlay>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <AdddBookModal
            showIt={showAddBookModal}
            sessionInfo={sessionInfo}
            initialCriteria={currentRow}
            onClose={handleAddBookModalClose} />
          <FilterModal
            showIt={showFilterModal}
            onClose={handleCloseFilterModal}
            suggestedFilters={filters}
            allowEmptyValue={true}
            filterColumns={[ 
              { columnName: 'title', title: 'Title' },
              { columnName: 'author', title: 'Author' },
              { columnName: 'borrowed', title: 'Borrowed' },
              { columnName: 'has_book', title: 'Has Book' },
              { columnName: 'no_book', title: 'No Book' },
              { columnName: 'has_reading', title: 'Has Reading'},
              { columnName: 'no_reading', title: 'No Reading'}
            ]} />
            <UploadLibbyTimelineModal
              showIt={showUploadLibbyTimelineModal}
              sessionInfo={sessionInfo}
              onClose={handleCloseUploadLibbyTimelineModal}
            />
        </Col>
      </Row>
    </Container>
  )
}

export default LibbyTimelineTab
